import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { getPerpetualUrl } from 'utils/getPerpetualUrl'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import Vote from '@pancakeswap/uikit/src/components/Svg/Icons/Vote'
import Resources from '@pancakeswap/uikit/src/components/Svg/Icons/Resources'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

// 所有的菜单配置
const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Trade'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [
          {
            label: t('Swap'),
            href: '/swap',
          },
          // {
          //   label: t('Limit'),
          //   href: '/limit-orders',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/decorations/3d-coin.png',
          // },
          {
            label: t('Liquidity'),
            href: '/liquidity',
          },
          // {
          //   label: t('Perpetual'),
          //   href: getPerpetualUrl({
          //     chainId,
          //     languageCode,
          //     isDark,
          //   }),
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
          // {
          //   label: t('Bridge'),
          //   href: 'https://bridge.pancakeswap.finance/',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Pools'),
        // label: t('Earn'),
        href: '/pools',
        icon: EarnIcon,
        fillIcon: EarnFillIcon,
        image: '/images/decorations/pe2.png',
        items: [
          // {
          //   label: t('Farms'),
          //   href: '/farms',
          // },
          {
            label: t('Pools'),
            href: '/pools',
            // supportChainIds: SUPPORT_ONLY_BSC,
          },
          {
            label: 'Create Pools',
            href: '/createPools',
            // href: '/createPools',
            // supportChainIds: SUPPORT_ONLY_BSC,
          },
          {
            label: 'Private Pools',
            href: '/privatePools',
            // href: '/createPools',
            // supportChainIds: SUPPORT_ONLY_BSC,
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      // {
      //   label: t('Win'),
      //   href: '/prediction',
      //   icon: TrophyIcon,
      //   fillIcon: TrophyFillIcon,
      //   supportChainIds: SUPPORT_ONLY_BSC,
      //   items: [
      //     {
      //       label: t('Trading Competition'),
      //       href: '/competition',
      //       image: '/images/decorations/tc.png',
      //       hideSubNav: true,
      //     },
      //     {
      //       label: t('Prediction (BETA)'),
      //       href: '/prediction',
      //       image: '/images/decorations/prediction.png',
      //     },
      //     {
      //       label: t('Lottery'),
      //       href: '/lottery',
      //       image: '/images/decorations/lottery.png',
      //     },
      //     {
      //       label: t('Pottery (BETA)'),
      //       href: '/pottery',
      //       image: '/images/decorations/lottery.png',
      //     },
      //   ],
      // },
      {
        label: 'IDO',
        href: '/ido',
        icon: TrophyIcon,
        fillIcon: TrophyFillIcon,
        items: [
          {
            label: 'IDO',
            href: '/ido',
            image: '/images/ifos/ifo-bunny.png',
          },
        ],
      },
      // {
      //   label: 'Ido',
      //   href: '/ifo',
      //   icon: TrophyIcon,
      //   fillIcon: TrophyFillIcon,
      //   items: [
      //     {
      //       label: 'Ido',
      //       href: '/ifo',
      //       image: '/images/ifos/ifo-bunny.png',
      //     },
      //   ],
      // },
      // {
      //   label: t('IFO'),
      //   href: '/ifo',
      //   icon: TrophyIcon,
      //   fillIcon: TrophyFillIcon,
      //   items: [
      //     {
      //       label: t('IFO'),
      //       href: '/ifo',
      //       image: '/images/ifos/ifo-bunny.png',
      //     },
      //   ],
      // },
      {
        label: t('NFT'),
        href: `/nfts`,
        icon: NftIcon,
        fillIcon: NftFillIcon,
        image: '/images/decorations/nft.png',
        items: [
          {
            label: 'Mint',
            href: `/nfts`,
          },
          // {
          //   label: t('Overview'),
          //   href: `${nftsBaseUrl}`,
          // },
          // {
          //   label: t('Collections'),
          //   href: `${nftsBaseUrl}/collections`,
          // },
          // {
          //   label: t('Activity'),
          //   href: `${nftsBaseUrl}/activity`,
          // },
        ],
      },
      {
        label: t('Home'),
        href: `/home`,
        icon: Vote,
        fillIcon: Resources,
        items: [
          {
            label: t('Home'),
            href: `/home`,
          }
        ],
      },
      // {
      //   label: '',
      //   href: '/info',
      //   icon: MoreIcon,
      //   hideSubNav: true,
      //   items: [
      //     {
      //       label: t('Info'),
      //       href: '/info',
      //     },
      //     {
      //       label: t('IFO'),
      //       href: '/ifo',
      //       supportChainIds: SUPPORT_ONLY_BSC,
      //       image: '/images/ifos/ifo-bunny.png',
      //     },
      //     {
      //       label: t('Voting'),
      //       href: '/voting',
      //       supportChainIds: SUPPORT_ONLY_BSC,
      //       image: '/images/voting/voting-bunny.png',
      //     },
      //     {
      //       type: DropdownMenuItemType.DIVIDER,
      //     },
      //     {
      //       label: t('Leaderboard'),
      //       href: '/teams',
      //       supportChainIds: SUPPORT_ONLY_BSC,
      //       image: '/images/decorations/leaderboard.png',
      //     },
      //     {
      //       type: DropdownMenuItemType.DIVIDER,
      //     },
      //     {
      //       label: t('Blog'),
      //       href: 'https://blog.pancakeswap.finance',
      //       type: DropdownMenuItemType.EXTERNAL_LINK,
      //     },
      //     {
      //       label: t('Docs'),
      //       href: 'https://docs.pancakeswap.finance',
      //       type: DropdownMenuItemType.EXTERNAL_LINK,
      //     },
      //   ].map((item) => addMenuItemSupported(item, chainId)),
      // },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
